@use "_vars.scss" as vars;


@media (min-width: 1200px) {
  body:not(.body_home) {
    #head_left {
      display: none;
    }
  }
}



header#head{
  position: relative;
  background-color: vars.$black;
  > div {
    position: relative;
  }

  #head_top {

  }


  #head_right {
    background-color: white;
    width: 72%;
    z-index: 3;
    position: relative;
    height: 100vh;
    #menu_right {
      > picture > img, > img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      div.button-menu {
        button.button-open-menu {
          position: absolute;
          top: 50px;
          right: 0;
          text-transform: uppercase;
          width: 117px;
          height: 77px;
          row-gap: 6px;
          border: none;
          color: vars.$white;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: vars.$color2;
          img {
            margin-top: 10px;
          }
          i.icon > svg {
            width: 40px;
            height: 30px;
            margin: 0;
            transform: unset;
            margin-bottom: 10px;
          }
        }
      }
      div.search_shortcut {
        div.text-container {
          position: absolute;
          text-wrap: nowrap;
          white-space: nowrap;
          top: 0;
          left: 2px;
          transform: translateX(-100%);
          background-color: vars.$color2;
          padding: 0 25px;
          max-width: 1000px;
          color: vars.$white;
          text-transform: uppercase;
          font-size: 20px;
          text-decoration: none;
          font-weight: bold;
          transition:  0.6s ease;
          display: flex;
          overflow: hidden;
          align-items: center;
          height: 100%;
        }
        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          i.icon > svg {
            height: 40px;
            width: 40px;
            margin: 0;
            transform: unset;
            pointer-events:all;
          }
        }
        cursor: pointer;
        background-color: vars.$color2;
        padding: 0;
        position: absolute;


        &:hover {
          /*div.text-container {
            display: flex;
            max-width: 1000px;
            padding: 0 25px;
          }*/
        }
        &.shortcut_1 {
          top: 20%;
          left: 70%;
        }
        &.shortcut_2 {
          top: 35%;
          left: 30%;
        }
        &.shortcut_3 {
          top: 65%;
          left: 70%;
        }
        &.shortcut_4 {
          top: 80%;
          left: 30%;
        }
      }
    }
  }

  #head_left {
    width: 28%;
    z-index: 3;
    background-color: vars.$black;
    height: 100vh;
    #menu_left {
      overflow-y: auto;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      height: 100%;
      padding: 20px 0;
      img.logo_alt {
        display: none;
      }
      div.button-menu {
        display: none;
      }
      div.mod_social {
        color: vars.$white;
        display: flex;
        align-items: end;
        column-gap: 10px;
        p {
          font-size: 15px;
          font-weight: 600;
          margin: 0;
        }
        section {
          margin-bottom: 2px;
          padding: 0;
          > div {
            gap: 10px !important;
            svg {
              height: 21px;
              width: 21px;
              transform: unset;
            }
          }

        }
      }
    }
  }

  #head_bottom {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2;
    background-color: vars.$white;
    #sticky_header {
      > a img {
        margin: 10px 40px;
      }
      display: flex;
      column-gap: 10px;
      align-items: center;
      div.button-menu {
        button.button-open-menu {
          text-transform: uppercase;
          width: 117px;
          height: 77px;
          border: none;
          color: vars.$white;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 6px;
          background-color: vars.$color2;
          i.icon > svg {
            width: 40px;
            height: 30px;
            margin: 0;
            transform: unset;
            pointer-events:all;
          }
        }
      }
      .module_socials {
        a {
          font-size: 16px;
          font-weight: 600;
          color: vars.$color2;
          text-decoration: none;
        }
        margin-left: auto;
        margin-right: 10px;
        i.icon > svg {
          width: 25px;
          height: 25px;
          margin: 0;
          transform: unset;
        }
        section {
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1550px) {
  header#head {
    #head_bottom {
      #sticky_header {
        .module_socials {
          margin-right: 0 !important;
          min-width: 120px;
          flex-direction: column;
          align-items: end !important;
          gap: 0 !important;
          section.hook_socials {
            a {
              i svg {
                width: 25px;
                height: 25px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: vars.$rupture_2_max) {
  header#head{
    #head-top {

    }
    #head_left {
      background-color: vars.$white;
      height: 150px;
      width: 100%;
      #menu_left {
        max-width: 90%;
        margin: auto;
        img.logo_alt {
          display: block;
        }
        img.logo {
          display: none;
        }
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
        padding: 20px 0;
        div.mod_social, div.social_container, div#left_menu_container {
          display: none!important;
        }
        div.button-menu {
          transform: translate(5px, -20px);
          display: block;
          text-transform: uppercase;
          border: none;
          color: vars.$white;
          button {
            border: none;
            background-color: transparent;

            i.icon > svg {
              fill: vars.$color2;
              width: 40px;
              height: 40px;
              margin: 0;
              transform: unset;
              pointer-events:all;
            }
          }
        }
      }
    }
    #head_right {
      width: 100%;
      margin-top: -2px;
      height: 517px;
      #menu_right {
        div.button-menu {
          display: none;
        }

        div.search_shortcut {
          padding: 5px;
          a i.icon > svg {
              height: 32px;
              width: 32px;
          }
          img {
            height: 30px;
            width: 30px;
          }
          div.text-container {
            padding: 0 15px;
            font-size: 16px;
            max-width: 1000px;
            display: flex;
          }
          &.shortcut_1 {
            top: 20%;
            left: 80%;
          }
          &.shortcut_2 {
            div.text-container {
              left: unset;
              right: 2px;
              transform: translateX(100%);
            }
            top: 35%;
            left: 10%;
          }
          &.shortcut_3 {
            top: 65%;
            left: 80%;
          }
          &.shortcut_4 {
            div.text-container {
              right: 2px;
              left: unset;
              transform: translateX(100%);
            }
            top: 80%;
            left: 10%;
          }
        }
      }
    }
    #head_bottom {
      display: none;
    }
  }
}

@media (max-width: 665px) {
  header#head {
    #head_right {
      #menu_right {
        div.search_shortcut {
          &.shortcut_4 {
            top: 50%;
            left: 10%;
            
          }
        }
      }
    }
  }
}