@use "../../_vars.scss" as vars;

#menu_haut {
    overflow: auto;
    div.container-xxl {
        > div.row {
            padding-top: 60px;
            div.siteInfos {
                overflow: hidden;
                position: relative;
            }
        }
    }
    img#zebra {

        position: absolute;
        opacity: 0.6;
        bottom: -190px;
    }
    z-index: 10001;
    top: 0;
    left: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: vars.$color2;
    display: none;
    opacity: 0;

    button.button-close-menu {
        position: fixed;
        right: 30px;
        top: 30px;
        border: 0;
        background: transparent;
        i.icon svg {
            margin: 0;
            height: 30px;
            width: 30px;
            transform: none;
            fill: vars.$black
        }
    }

    div.addr_bloc {
        font-weight: 400;
        font-size: 20px;
        color: vars.$white;
    }

    div.mod_social {
        color: vars.$white;
        font-weight: 400;
        font-size: 20px;
        section {
            padding:0;
            svg {
                height: 30px;
                width: 30px;
                fill: vars.$black;
                transform: unset;
                path {
                    fill: vars.$black;
                }
            }
        }

    }

    ul.navbar-nav {
        margin-top: 140px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        column-gap: 80px;
        row-gap: 0;


        li.nav-item {
            width: calc(50% - 50px);
            font-size: 20px;
            text-transform: uppercase;
            font-weight: bold;
            padding: 25px 0;
            border-bottom: 1px solid black;
            background: url("/public/images/list_style_menu_fullscreen.svg") left top 30px no-repeat;
            a {
                padding-left: 50px;
                color: vars.$white;
                transition: 0.4s ease;
                &:hover {
                    background: transparent;
                    color: vars.$black;
                }
            }
            ul {
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 50px;
                background-color: transparent;
                border: none;
                li {
                    padding-bottom: 5px;
                    list-style: none;
                    a {
                        white-space: break-spaces;
                        text-wrap: wrap;
                        padding-top: 0;
                        padding-bottom: 0;
                        padding-left: 0px;
                        text-transform: none;
                        font-weight: 600;
                        font-size: 18px;
                        color: vars.$white;
                    }
                }
            }
        }
    }
}

@media (max-width: vars.$rupture_2_max) {
    #menu_haut {
        div.container-xxl {
            > div.row {
                flex-direction: row-reverse;
                padding-top: 16px;
                padding-bottom: 16px;
                div.siteInfos {
                    position: unset;
                    order: 2;
                    font-size: 13px;
                    > div {
                        margin: 10px;
                    }
                }
            }
        }
        button.button-close-menu {
            right: 30px;
            top: 30px;
        }
        img.logo {
            position: absolute;
            top: 0px;
            height: 136px;
            width: 200px;
            left: 50%;
            transform: translateX(-50%);
        }
        img#zebra {
            display: none;
        }
        height: 100%;
        overflow-y: scroll;
        min-height: 100vh;
        div.addr_bloc {
            font-weight: 400;
            color: vars.$white;
            font-size: 13px
        }

        div.mod_social {
            font-size: 13px;
            color: vars.$white;
            font-weight: 400;
            section {
                padding:0;
                svg {
                    fill: vars.$black;
                    transform: unset;
                }
            }

        }

        ul.navbar-nav {
            margin: 120px 10px 0 10px;
            display: block;
            height: unset;
            min-height: unset;
            width: 100%;
            li.nav-item {
                width: 100%;
                font-size: 15px;
                text-transform: uppercase;
                font-weight: bold;
                padding: 10px 32px 10px 0;
                border-bottom: 1px solid black;
                background: url("/public/images/list_style_menu_fullscreen.svg") left top 15px no-repeat;

                a {
                    color: vars.$white;
                }
                ul {
                    padding-left: 50px;
                    padding-top: 0px;
                    padding-bottom: 0px;

                    li {
                        list-style: none;
                        a {
                            font-size: 15px;
                            text-transform: none;
                            font-weight: 600;
                            color: vars.$white;
                        }
                    }
                }
            }
        }
    }

}