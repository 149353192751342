@use "../../_vars.scss" as vars;


#menu_sticky {
    display: flex;
    column-gap: 30px;
    row-gap: 20px;
    font-size: 16px;
    font-weight: 700;
    list-style: none;
    a {
        color: vars.$color2;
        text-decoration: none;
    }
}