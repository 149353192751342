@use "_vars.scss" as vars;

body:not(.body_home) {
  main#content {
    padding-top: 1rem;
  }
}


main#content{
  background-color: #fff;
}


div.home_form_container {
  background-color: vars.$color2;
}



nav#breadcrumbs {
  margin-top: 30px;
  ol {
    font-size: 15px;
    margin-bottom: 0;
    li {
      a {
        text-decoration: none;
        > span {
          color: vars.$black;
          font-weight: 200;
        }
      }
      > span {
        color: vars.$color2;
        font-weight: 600;
      }
    }

  }
  .breadcrumb-item+.breadcrumb-item::before {
    content: ">";
  }
}
section.pag_layout_default {
  padding-top: 0;

  div.pages_bloc_content_small {
    font-size: 30px;
    line-height: 42px;
    font-weight: 300;
  }

  div.page_image {
    margin-top: 99px;
  }

  div.page_bloc_content2 {
    position: relative;
    img#content2-top-corner {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-44%,-8%) rotate(-12deg);
    }
    img#content2-bottom-corner {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(39%,20%) rotate(154deg);
    }
    background-color: vars.$color2;
    color: vars.$white;
    font-weight: 600;

    .h3,h3 {
      color:#FFF;
    }
    .h4,h4 {
      color:#fff;
    }

    ul {
      padding: 0;
      li {
        background: url("/public/images/list-deco-2.svg") left top no-repeat;
        margin-bottom: 15px;
        font-weight: 600;
        padding-left: 50px;
        list-style: none;
        font-size: 17px;
      }
      ul  {
        margin-top: 15px;
        > li {
          list-style: none;
          background: none;
          padding-left: 0;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

  }

  div.page_bloc_content1, div.page_bloc_content3 {
    font-weight: 600;
    ul {
      padding: 0;
      li {
        padding-left: 50px;
        list-style: none;
        background: url("/public/images/list-deco-1.svg") left top no-repeat;
        color: vars.$color2;
        margin-bottom: 15px;
        font-weight: 600;
        font-size: 17px;
        ul  {
          margin-top: 12px;
          > li {
            font-size: 15px;
            font-weight: 300;
            line-height: 19px;
            margin-bottom: 18px;
            background: none;
            padding-left: 0;
            color: vars.$black;
          }

        }
      }
    }
  }

}




section {
  .bloc_content {

    .h3,h3{
      font-size: 17px;
      color:#EF7D00;
    }
    .h4,h4{
      font-size: 15px;
      color:#000;
    }

    .row {
      --bs-gutter-x: 6rem;
    }
    padding-top: 90px;
    padding-bottom: 90px;

    p {
      font-size: 15px;
      line-height: 19px;
      font-weight: 400;
    }



    h2 {
      font-weight: bolder;
      text-transform: uppercase;
      position: relative;
      &:before {
        content: "";
        background-image: url("/public/images/title-decoration2.svg");
        width: 67px;
        height: 66px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-45%, -95%);
      }
    }



  }
  &:not(.pag_layout_home) {
    h2 {
      &:before {
        background-image: url("/public/images/title-decoration3.svg");

      }
    }
  }
}


// LG
@media (max-width: 1200px) and (min-width: 992px) {
  section .bloc_content {
    row-gap: 20px;
  }
  section.pag_layout_default div.pages_bloc_content_small {
    font-size: 36px;
    line-height: 40px;
  }
}

// MD
@media (max-width: 991px) and (min-width: 768px) {
  section.pag_layout_default div.pages_bloc_content_small {
    font-size: 32px;
    line-height: 36px;
  }
  section .bloc_content {
    .row {
      --bs-gutter-x: 4rem;
    }
  }
}

@media (max-width: 767px) {
  section.pag_layout_home {
    div.home_bloc_content3 {
      img {
        margin-left: 0 !important;
        max-width: 300px !important;
      }
    }
  }
}

@media (max-width: 767px) and (min-width: 576px) {
  section.pag_layout_default div.pages_bloc_content_small {
    font-size: 28px;
    line-height: 32px;
  }
}

@media (max-width: 575px) {
  section.pag_layout_default div.pages_bloc_content_small {
    font-size: 24px;
    line-height: 28px;
  }

  section .bloc_content {
    .row {
      --bs-gutter-x: 1.5rem;
    }
    padding-top: 80px;
    padding-bottom: 50px;
  }
}



