@use "../../_vars.scss" as vars;

.home_properties_container {
    background-color: vars.$color2;
}


section.hook_properties {
    padding: 94px 0 150px;
    h2 {
        margin-bottom: 43px;
        color: vars.$white;
        font-weight: bold;
        position: relative;
        text-transform: uppercase;
        &:before {
            content: "";
            background-image: url("/public/images/title-decoration.svg");
            width: 67px;
            height: 66px;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, -100%);
        }
    }
    div.glide {
        position: relative;
        div.glide__track {
            max-width: 1640px;
            width: 100%;
            margin: auto;
        }
        .glide__slides {
            display: flex;
            align-items: center;
        }
    }
    div.glide--controls {
        button {
            background-color: transparent;
            border: 0;
            i.icon img {
                height: 12px;
                width: 56px;
            }
        }
        button.glide--prev {
            position: absolute;
            left: -50px;
            top: 50%;
            transform: translate(-100%, -50%);
        }
        button.glide--next {
            position: absolute;
            right: -50px;
            top: 50%;
            transform: translate(100%, -50%);
        }
    }

    #property-mobile-btn {
        display: none;
    }
    div.hook_properties_property {
        position: relative;
        div.property-transaction {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            background-color: vars.$black;
            color: vars.$white;
            text-transform: uppercase;
            padding: 10px 20px;
            font-size: 20px;
            font-weight: 500;

        }
        div.property-info {
            padding: 75px 35px 10px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.75);
            h3 {
                color: vars.$color2;
                font-weight: 600;
            }
            h4 {
                color: vars.$black;
                font-size: 17px;
                font-weight: 600;
            }
        }
        button.property_btn {
            position: absolute;
            bottom: 22px;
            left: 0;
            padding: 0;
            border: 0;
        }


    }


}

@media (max-width: vars.$rupture_2_max) {
    section.hook_properties {
        div.glide {
            div.glide__track {
                width: 120%;
            }
        }
        div.glide--controls {
            display: none;
        }

    }
}

@media (max-width: vars.$rupture_2_max) {
    section.hook_properties {
        padding: 47px 0 47px 0;
        h2 {
            margin-bottom: 43px;
            color: vars.$white;
            font-weight: 800;
        }
        a.white-custom-btn {
            display: none;
        }
        #property-mobile-btn {
            display: block;
            width: fit-content;
            margin-left: auto;
        }
        div.glide {
            div.glide__track {
                width: 120%;
            }

        }

        div.hook_properties_property {
            position: relative;
            div.property-transaction {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                background-color: vars.$black;
                color: vars.$white;
                text-transform: uppercase;
                padding: 10px 20px;
                font-size: 14px;
                font-weight: 500;

            }
            div.property-info {
                padding: 50px 14px 18px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                background-color: rgba(255, 255, 255, 0.75);
                h3 {
                    color: vars.$color2;
                    font-size: 20px;
                    font-weight: 600;
                }
                h4 {
                    color: vars.$black;
                    font-size: 13px;
                    font-weight: 600;
                }
            }


        }


    }
}

@media (max-width: vars.$rupture_5_max) {
    section.hook_properties {
        div.glide {
            div.glide__track {
                width: 200%;
            }
            .glide__slide {
                img {
                    max-height: 350px;
                    object-fit: cover;
                }
            }
        }
        #property-mobile-btn {
            background: white;
            padding: 10px;
            text-align: center;
            margin: auto;
        }
    }
}