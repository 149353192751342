@use "../scss/_vars.scss" as vars;
#content{
    .googlemapsembed, .youtube_player{
        width: 100% !important;
        height: auto !important;
    }
}
#tarteaucitronRoot {
    #tarteaucitronAlertBig.tarteaucitronAlertBigBottom {
	background-color: #FFF !important;
	color: #333 !important;
	font-size: 13px !important;
	padding: 12px 3px 7px 3px !important;  
        box-shadow: 0px 6px 36px -6px rgba(0,0,0,0.4);
        font-family:vars.$color_tarteaucitron !important;
        #tarteaucitronPrivacyUrl, #tarteaucitronPrivacyUrlDialog, #tarteaucitronDisclaimerAlert, #tarteaucitronDisclaimerAlert strong {
            font-size: 13px !important;
            color: #333 !important;
            font-family:vars.$color_tarteaucitron !important;
        }
        .tarteaucitronAllow, .tarteaucitronDeny{
            background-color:vars.$color_tarteaucitron !important;
            color:vars.$color_tarteaucitron2;
            border:1px solid vars.$color_tarteaucitron !important;
            padding: 5px 10px !important;
            font-size: 12px !important;
            margin-left: 8px !important;
            margin-right: 8px !important;
            outline:0 !important;
            max-width:140px;
            font-family:vars.$color_tarteaucitron !important;
            &:hover{
                background-color:vars.$color_tarteaucitron2 !important;
                color:vars.$color_tarteaucitron !important;
                .tarteaucitronCheck::before, .tarteaucitronCross::before{
                    color:vars.$color_tarteaucitron !important;
                }
            }
        }        
        #tarteaucitronCloseAlert{
            background-color:vars.$color_tarteaucitron2 !important;
            color:vars.$color_tarteaucitron;
            border:1px solid vars.$color_tarteaucitron !important;
            padding: 5px 10px !important;
            font-size: 12px !important;
            margin-left: 8px !important;
            margin-right: 8px !important;
            outline:0 !important;  
            max-width:120px;
            font-family:vars.$color_tarteaucitron !important;
            &:hover{
                background-color:vars.$color_tarteaucitron !important;
                color:vars.$color_tarteaucitron2 !important;
            }
        }
    }
    
    a:focus-visible, button:focus-visible{
        outline:0 !important;
    }
    &.tarteaucitronBeforeVisible::before {	
	background: #000 !important;
    }
    .tarteaucitronPlus::before {
        color: #555 !important;
    }
    #tarteaucitronAlertBig.tarteaucitronAlertBigTop {
	width: 450px !important;
	max-width: 88% !important;
	background: #FFF !important;
        border-radius: 10px !important;
        padding: 5px 20px 20px !important;
        #tarteaucitronDisclaimerAlert{
            color:#333 !important;
            font-size: 13px !important;
            text-align:justify;
            padding: 0;
            line-height: 19px;
            margin: 18px 0 30px !important;
            font-family:vars.$color_tarteaucitron !important;
            a{
                color:#333 !important;
                text-decoration:underline;
                &.btnDenyAll{
                    display: block;
                    text-align:right;
                    margin-bottom: 10px;
                    color:vars.$color_tarteaucitron !important;
                    font-size: 14px;
                    font-weight: bold;
                    font-family:vars.$color_tarteaucitron !important;
                }
            }
            img{
                max-width: 430px;
                max-height: 100px;
                margin-bottom: 20px;
                padding: 10px;
                margin: 0 auto 0px;
                display: table;
                @media (max-width: 520px) {
                    max-width: 94%;
                }
            }
        }
        .tarteaucitronAllow, .tarteaucitronDeny{
            background-color:vars.$color_tarteaucitron !important;
            color:vars.$color_tarteaucitron2;
            border:2px solid vars.$color_tarteaucitron !important;
            padding: 8px 13px !important;
            float:right;
            font-size: 15px !important;
            margin-left: 25px !important;
            outline:0 !important;
            max-width:140px;
            font-family:vars.$color_tarteaucitron !important;
            &:hover{
                background-color:vars.$color_tarteaucitron2 !important;
                color:vars.$color_tarteaucitron !important;
                .tarteaucitronCheck::before, .tarteaucitronCross::before{
                    color:vars.$color_tarteaucitron !important;
                }
            }
        }        
        #tarteaucitronCloseAlert{
            background-color:vars.$color_tarteaucitron2 !important;
            color:vars.$color_tarteaucitron;
            border:2px solid vars.$color_tarteaucitron !important;
            padding: 8px 15px !important;
            float:right;
            font-size: 15px !important;
            margin-left: 0 !important;
            outline:0 !important;  
            max-width:120px;
            font-family:vars.$color_tarteaucitron !important;
            &:hover{
                background-color:vars.$color_tarteaucitron !important;
                color:vars.$color_tarteaucitron2 !important;
            }
        }
        @media (max-width: 520px) {
            .tarteaucitronAllow, .tarteaucitronDeny{
                font-size: 13px !important;
                margin-left: 5px !important;
            }
            #tarteaucitronCloseAlert{
                font-size: 13px !important;
            }
        }
        @media (max-width: 419px) {
            .tarteaucitronAllow, .tarteaucitronDeny, #tarteaucitronCloseAlert{
                float:none !important;
                margin: 10px auto !important;
                width: 100px !important;
                max-width:88% !important;
            }
        }
        /*.tarteaucitronCheck{
            &::before{
                content:"" !important;
                display:none !important;
            }
        }*/
    }
    .tarteaucitronCTAButton, #tarteaucitronCloseAlert{
        border-radius: 4px !important;
    }
    #tarteaucitronPercentage {
        height: 2px !important;
    }
    #tarteaucitronBack{
        background-color:#000 !important;
        opacity: 0.5 !important;
    }
    #tarteaucitron {
        background-color:#FFF !important;
        padding:10px 0 0;
        overflow:hidden;
        border-radius: 10px !important;
        #tarteaucitronClosePanel{
            background-color:transparent !important;
            color:#333 !important;
            outline: 0 !important;
        }
        #tarteaucitronServices {
            background-color: transparent !important;
            box-shadow:none !important;
            margin-top: 25px !important;
            padding: 0 10px 0 20px;
            *{
                font-family:vars.$color_tarteaucitron !important;
            }
            .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow, .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny, .tarteaucitronIsDenied .tarteaucitronAllow, .tarteaucitronIsAllowed .tarteaucitronDeny {
                opacity: 0.2 !important;
                &:hover{
                    opacity:1 !important;
                }
            }
            .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow, .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny {
                opacity:1 !important;
            }
            #tarteaucitronServices_mandatory button.tarteaucitronAllow{
                background:#1B870B !important;
                opacity:1 !important;
            }
            .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow{
                background:#1B870B !important;
            }
            .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny{
                background:#9C1A1A !important;
            }
            
            .tarteaucitronMainLine {
                background-color: #transparent !important;
                border: 0 !important;
                &:hover{
                    background-color: #transparent !important;
                }
            }
            .tarteaucitronLine{
                background:transparent !important;
            }
            .tarteaucitronBorder{
                background-color: transparent !important;
                border: 0 !important;
                >ul{
                    margin-bottom: 20px;
                    >li{
                        border-top:1px solid #ddd !important;
                    }
                }
                
                .tarteaucitronTitle {
                    >button{
                        background: transparent !important;
                        color: #333 !important;
                        padding: 5px !important;
                    }
                }
            }
        }
    }
    .tarteaucitronH1 {
	color: #333 !important;
        text-align:left !important;
        margin:0 0 15px !important;
    }
    .tarteaucitronH2 {
	color: #333 !important;
    }
    .tarteaucitronH2 {
	font-size: 15px !important;
    }
    .tarteaucitronH3 {
	font-size: 15px !important;
    }
    #tarteaucitronInfo {
	background: transparent !important;
	color: #333 !important;
        border:0;
        text-align: left !important;
        max-width: 100% !important;
        font-size: 14px !important;
    }    
    #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden, #tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
        background:transparent !important;
    }
}