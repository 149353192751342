@use "../../_vars.scss" as vars;



section.hook_news {
    margin-top: 80px;
    > div.row {
        position: relative;
        > img#news-bottom-corner {
            width: 287px;
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translate(32%,44%);
        }
    }
    div.news_footer {
        display: none;
        margin-top: 25px;
        a {
            margin-left: auto;
        }
    }
    div.news_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 47px;
        h2.hook_title {
            position: relative;
            text-transform: uppercase;
            font-weight: bolder;
            &:before {
                content: "";
                background-image: url("/public/images/title-decoration4.svg");
                width: 67px;
                height: 66px;
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(50%, -100%);
            }
        }
    }



    div.bloc_new {
        div.bloc_img_bloc {
            button.btn-share {
                opacity: 0;
                position: absolute;
                top: 20px;
                left: 0;
                background-color: vars.$color2;
                height: 60px;
                width: 120px;
                border: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.4s ease;
                i > svg {
                    transform: unset;
                    pointer-events: all;
                    margin: unset;
                    height: 30px;
                    width: 30px;
                    fill: vars.$white;
                    path {
                        transition: 0.4s ease;
                    }
                }
                &:hover {
                    background-color: vars.$white;
                    color: vars.$color2;
                    i > svg {
                        path {
                            stroke: vars.$color2!important;
                        }
                    }
                }
            }
            position: relative;
            &:hover {
                div.overlay {
                    opacity: 1 !important;
                }
                button.btn-share {
                    opacity: 1;
                }
            }
            > img {
                width: 100%;
            }
            div.overlay {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                color: vars.$white;
                position: absolute;
                width: 100%;
                height: 255px;
                bottom: 0;
                opacity: 1;
                transition: 0.4s ease;
                background-color: rgba(239, 125, 0, 0.8);
                .overlay_name {
                    h3 {
                        background-color: #ef7d00;
                        font-weight: bolder;
                        text-transform: uppercase;
                        font-size: 18px;
                        width: 100%;
                        margin-bottom: 0;
                        padding: 14px 30px;
                    }
                    width: fit-content;
                }

                div.overlay-content {
                    p {
                        margin: 0;
                    }
                    padding: 10px 15px;
                    font-size: 16px;
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    img {
                        position: absolute;
                        bottom: 25px;
                        right: 33px;
                    }
                }
            }
        }
        &:not(.bloc_new_first) {
            div.bloc_img_bloc {
                button.btn-share {
                    height: 30px;
                    width: 60px;
                    i > svg {
                        height: 15px;
                        width: 15px;
                    }
                }
                div.overlay {
                    width: 100%;
                    height: 124px;
                    opacity: 0;
                    h3 {
                        font-size: 14px;
                        padding:5px 15px;
                        width: fit-content;
                        background-color: rgba(239, 125, 0, 1);
                    }
                    div.overlay-content {
                        font-size: 14px;
                        padding: 5px 15px;
                        img {
                            position: absolute;
                            bottom: 18px;
                            right: 18px;
                        }
                    }
                }
            }
        }
    }
}




section#tpl_news {
    h1 {
        color: vars.$black;
        text-transform: uppercase;
    }
    div.input-group {
        align-items: center;
    }

    div#elemsContainer {
        row-gap: var(--bs-gutter-x);
        div.bloc_img_bloc {
            button.btn-share {
                opacity: 0;
                position: absolute;
                top: 20px;
                left: 0;
                background-color: vars.$color2;
                height: 40px;
                width: 90px;
                border: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.4s ease;
                i > svg {
                    transform: unset;
                    pointer-events: all;
                    margin: unset;
                    height: 20px;
                    width: 20px;
                    > path {
                        fill: vars.$white;
                        transition: 0.4s ease;
                    }
                }
                &:hover {
                    background-color: vars.$white;
                    color: vars.$color2;
                    i > svg > path {
                        fill: vars.$color2
                    }
                }
            }
            position: relative;

            &:hover {
                div.overlay {
                    opacity: 1;
                }
                button.btn-share {
                    opacity: 1;
                }
            }

            div.overlay {
                position: relative;
                max-height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                color: vars.$white;
                position: absolute;
                width: 100%;
                height: 200px;
                bottom: 0;
                opacity: 0;
                transition: 0.4s ease;
                background-color: rgba(239, 125, 0, 0.8);

                h3 {
                    background-color: #ef7d00;
                    font-weight: bolder;
                    padding: 15px;
                    text-transform: uppercase;
                    font-size: 18px;
                    width: 100%;
                    margin-bottom: 0;
                }

                div.overlay-content {
                    p {
                        margin: 0;
                    }

                    padding: 15px;
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    img {
                        position: absolute;
                        bottom: 15px;
                        right: 25px;
                        scale: 0.8;
                    }
                }
            }
        }
    }



    div.ts-wrapper {
        border-radius: 0;
        color: vars.$color2;
        background: url("/public/images/angle-down-select.png") right 1rem center no-repeat;
        .ts-control {
            padding: 0;
            .item {
                padding: 0.375rem 0.75rem !important;
            }
            input {
                min-width: unset;
                height: 100%;
                border-right: 1px solid black !important;
                padding: 0.375rem 0.75rem !important;
            }
        }
    }


    &.tpl_detail {
        div.img-bandeau {
            max-width: 90%;
        }
        h1 {
            margin-top: 0;
            font-weight: 600;
            text-transform: uppercase;
            color: vars.$color2;
            margin-bottom: 40px;
        }

        div.catchphrase {
            font-size: 22px;
            line-height: 40px;
            margin-bottom: 40px;
        }
    }
}

@media (max-width: 1200px) {

    section.hook_news {
        > div.row {

            > img#news-bottom-corner {
                display: none;
            }
        }
        div.news_footer {
            display: flex;
            justify-content: center;
            a {
                margin-left: unset;
            }
        }
        div.news_header {
            a {
                display: none;
            }
        }
        div.bloc_new {
            div.bloc_img_bloc {
                div.overlay {
                    opacity: 1;
                }
                button.btn-share {
                    opacity: 1;
                }
            }
            &.bloc_new_first {
                .bloc_link {
                    width: fit-content;
                    display: block;
                    margin: auto;
                }
            }
            &:not(.bloc_new_first) {
                display: none;
            }
        }
    }

    section#tpl_news {
        div#elemsContainer {
            div.bloc_img_bloc {
                button.btn-share {
                    opacity: 1;
                }
                div.overlay {
                    height: 200px;
                    max-height: 100%;
                    opacity: 1;
                    h3 {
                        padding: 10px 15px;

                    }
                    div.overlay-content {
                        p {
                            margin: 0;
                        }

                        padding: 15px;
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        img {
                            position: absolute;
                            bottom: 25px;
                            right: 33px;
                        }
                    }
                }
            }
        }
        &.tpl_detail {
            h1 {
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 40px;
            }

            div.catchphrase {
                font-size: 35px;
                line-height: 40px;
                margin-bottom: 40px;
            }
        }
    }
}

@media (max-width: 991px) {
    section#tpl_news {
        &.tpl_detail {
            div.img-bandeau {
                max-width: 100%;
            }
            div.catchphrase {
                font-size: 30px;
            }
        }
    }
}

@media (max-width: vars.$rupture_5_max) {
    section.hook_news {
        div.bloc_new {
            div.bloc_img_bloc {
                button.btn-share {
                    display: none;
                    top: 5px;
                    height: 40px;
                    width: 90px;
                    i > svg {
                        height: 25px;
                        width: 25px;
                    }
                }
                div.overlay {
                    height: 100%;
                    h3 {
                        padding: 10px 15px;
                        margin-bottom: 0;
                    }
                    div.overlay-content {
                        padding: 10px;
                        p {

                        }
                        img {
                            bottom: 10px;
                            right: 10px;
                            transform: scale(0.8);
                        }
                    }
                }
            }
        }
        div.news_footer {
            a.orange-custom-btn {
                width: 100%;
                background: vars.$color2;
                padding: 10px 20px;
                text-align: center;
            }
        }
    }
    section#tpl_news {
        div#elemsContainer {
            div.bloc_img_bloc {
                div.overlay {
                    height: 170px;
                }
            }
        }
        &.tpl_detail {
            div.catchphrase {
                font-size: 26px;
            }
        }
    }
}