@use "../../_vars.scss" as vars;

section#tpl_detail_propertie {
    div.lightbox {
        margin-top: 70px;
        .swiper-wrapper {
            display: flex;
            .swiper-slide {
                display: flex;
                width: auto;
                max-width: 100%;
                img {
                    max-height: 591px;
                    object-fit: contain;
                }
            }
        }
    }
    font-size: 16px;
    padding: 0;
    .property_head {
        h1 {
            // SAME STYLE AS H1 UI.SCSS
            margin-bottom: 55px;
        }
    }
    div.lightbox {
        img {
            cursor: pointer;
        }
    }
    div.actions-container {
        top: 0;
        z-index: 1;
        right: 10px;

        button.btn-share {
            i.icon > svg path {
                stroke: vars.$color2;
                fill: none;
            }
            &:hover {
                i.icon > svg path {
                    stroke: black;
                    fill: none;
                }
            }
        }

        button {
            background: transparent;
            margin: 0;
            border: 0;
            padding: 10px;

            i.icon > svg {
                fill: vars.$color2;
                transform: none;
                height: 30px;
                width: 30px;
                margin: 0;

                path {
                    transition: 0.4s ease;
                    fill: vars.$white;
                    stroke: vars.$color2;
                }

                &:hover {
                    path {
                        fill: vars.$black;
                    }

                }
            }

            &.fav:not(.favored) {
                i.icon svg {

                    &:hover {
                        stroke: vars.$black;
                    }
                }
            }

            &:not(.btn-share) {
                padding: 10px;

                i.icon svg {
                    height: 30px;
                    width: 30px;
                    stroke: vars.$color2;
                    stroke-width: 75px;
                    fill: transparent;
                }
            }

            &.favored {
                i.icon svg {
                    height: 30px;
                    width: 30px;
                    stroke: none;
                    fill: vars.$color2;
                    path {
                        fill: vars.$color2
                    }

                }

                &.active {
                    i.icon svg {
                        stroke: vars.$color2;
                        fill: vars.$color2;
                    }
                }
            }
        }
    }

    .property_content {
        p {
            font-size: 30px;
            line-height: 42px;
            font-weight: 300;
            strong {
                color: vars.$color2;
                padding-right: 5px;
            }
        }
    }


    .property_contact {
        background-color: vars.$color2;
        padding-top: 50px;
        h2 {
            margin-bottom: 38px;
            color: vars.$white;
            text-transform: uppercase;
            font-weight: 800;
            position: relative;
            &:before {
                content: "";
                background-image: url("/public/images/title-decoration3.svg");
                width: 67px;
                height: 66px;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                transform: translate(-50%, -75%);
            }
        }
    }

    .property_options_background {
        .row {
            --bs-gutter-x: 4.5rem;
        }
        background-color: vars.$color2;
        padding-top: 118px;
        ul {
            padding-left: 0;
        }
        .property_options {
            padding: 0 30px;
            color: vars.$white;
            h2 {
                margin-bottom: 38px;
                color: vars.$white;
                text-transform: uppercase;
                font-weight: 800;
                position: relative;
                &:before {
                    content: "";
                    background-image: url("/public/images/title-decoration3.svg");
                    width: 67px;
                    height: 66px;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: translate(-50%, -75%);
                }
            }
            div.property_services {
                p {
                    margin-left: calc(2rem + 50px);
                }
            }
            div {
                &:not(:last-child) {
                    margin-bottom: 100px;
                }
                ul {

                    li {
                        padding-left: 50px;
                        list-style: none;
                        background: url("/public/images/list-deco-2.svg") left top no-repeat;
                        margin-bottom: 15px;
                        color: vars.$white;
                        font-size: 20px;
                        font-weight: 500;

                    }
                }
            }
        }
    }
    .property_contact {
        section.fullForms {
            padding-top: 0;
        }
    }
}

@media (max-width: 1200px) {
    section#tpl_detail_propertie {
        .property_content {
            p {
                font-size: 26px;
            }
        }
    }
}

@media (max-width: 767px) {
    section#tpl_detail_propertie {
        .property_content {
            p {
                font-size: 22px;
            }
        }
    }
}

@media (max-width: 576px) {
    section#tpl_detail_propertie {
        .property_content {
            p {
                font-size: 18px;
            }
        }
    }
}