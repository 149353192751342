@use "scss/_vars.scss" as vars;

@use "scss/_librairies.scss";
@use "scss/_scopes.scss";


@use "scss/_header.scss";
@use "scss/_content.scss";
@use "scss/_footer.scss";

@use "scss/_ui.scss";

@use "lib/_tarteaucitron.scss";
@use "scss/_modules.scss";

@use "scss/_overrides.scss";

html {
    overflow-y: scroll;
    overflow-x: hidden !important;
    scroll-behavior: smooth;
}
img{
    max-width: 100%;
    height: auto;
}
.btn-action-add{
    margin-top: 1rem;
    float: right;
}
b, strong {
    font-weight: 600;
}
a {
    color: inherit;
    text-decoration: none;
    &:hover {
        color: inherit;
        text-decoration: underline;
    }
    &:visited {
        color: inherit;
    }
}
body {
    font-family: vars.$font1;
    overflow-x: hidden !important;
    background-color: #f7f9fc;
    height: 100%;
    &.modal-open {
        padding-right: 0 !important;
    }
}
main#global{
    height: 100%;
    #main{
        height: 100%;
    }
}

a {
    &:focus, &:active, &.btn:focus, &.btn:active:focus, &.btn.active:focus, &.btn.focus, &.btn.focus:active, &.btn.active.focus {
        outline: 0;
    }
}

/* Body disabled (loading) */
.lds-ripple {
    display: inline-block;
    position: fixed;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
}

.lds-ripple div {
    transform: scale(2.5);
    position: absolute;
    border: 4px solid vars.$loadColor;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    transform: scale(2.5);
    animation-delay: -0.5s;
}

body.disabled {
    opacity: 0.2;
    position: relative;
    z-index: 0;
    transition-duration: 0.6s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.full{
    width: 100%;
}

#cookie-bar {
    background-color: #111111;
    line-height: 24px;
    color: #eeeeee;
    text-align: center;
    padding: 3px 0;

    p {
        margin: 0;
        padding: 0;
    }

    a {
        color: #000;
        display: inline-block;
        border-radius: 3px;
        text-decoration: none;
        padding: 0 6px;
        margin-left: 8px;
    }

    .cb-enable {
        background: #FFF;

        &:hover {
            background: #FFF;
        }
    }

    .cb-disable {
        background: #990000;

        &:hover {
            background: #bb0000;
        }
    }

    .cb-policy {
        background: #0033bb;

        &:hover {
            background: #0055dd;
        }
    }
}

i {
    vertical-align: middle;
    text-align: center;
    position: relative;
    svg {
        fill: vars.$white;
        width: 15px;
        height: 15px;
        position: relative;
        transform: translateY(-25%);
        margin-right: 5px;
    }
}
i.icon-dark{
    svg{
        fill: vars.$black;
    }
}
