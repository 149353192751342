@use "_vars.scss" as vars;

[invisible]{
  opacity: 0;
  filter: blur(5px);
}

[blur]{
  transition-duration: 0.4s;
  filter: blur(15px);
}


[visible]{
  opacity: 1;
  transition-duration: 0.4s;
  filter: blur(0);
  transform: translateX(0);
}

@media (prefers-reduced-motion) {
  [visible]{
    transition: none;
  }
}
@media (min-width: vars.$rupture_4_min) and (max-width: vars.$rupture_4_max) {
  [invisible]{
    opacity: 1;
    filter: blur(0);
  }
}