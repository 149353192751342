@use "../../_vars.scss" as vars;


.fullForms {
    div#google-legals {
        font-size: 12px;
        color: vars.$white;
        margin-top: 5px;
        a {
            color: vars.$white;
            text-decoration: none;
            transition: 0.4s ease;
            &:hover {
                color: vars.$black;
            }
        }
    }


    padding-top: 95px;
    padding-bottom: 95px;
    .fullForms-title {
        width: fit-content;
        color: white;
        font-weight: bolder;
        text-transform: uppercase;
        margin-bottom: 44px;
        position: relative;
        &:before {
            content: "";
            background-image: url("/public/images/title-decoration.svg");
            width: 67px;
            height: 66px;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(40%, -90%);
        }
    }
    a {
        color: vars.$black;
        text-decoration: none;
        font-weight: 500;
    }
    form {
        input.form-control {
            border-radius: 0;
            height: 50px;
            &::placeholder {
                color: vars.$black;
            }
        }
        textarea.form-control {
            border-radius: 0;
            &::placeholder {
                color: vars.$black;
            }
        }
        input.form-check-input {
            border: 0;
            border-radius: 0;
        }
        span.rgpd {
            color: vars.$white;
        }
    }
    &.form_page {
        padding: 0;
        input,textarea {
            border-color: vars.$color2;
            &:focus {
                box-shadow: 0 0 0 0.1rem rgba(239, 125, 0, 0.25);
            }
        }
        div.form-check {
            input.form-check-input {
                width: 1.2em;
                height: 1.2em;
                border: 2px solid vars.$color2;
                &:checked {
                    background-color: vars.$color2;
                    border-color: vars.$color2;
                }

                &:focus {
                    box-shadow: none;
                }
            }
            span.rgpd {
                color: vars.$color2;
            }

        }
        #google-legals {
            color: vars.$color2;
        }
    }
}

@media (min-width: 1300px) {
    #secondaryForm:not(.sendForm) {
        padding-left: 150px;
    }
}

@media (max-width: vars.$rupture_5_max) {
    .fullForms {
        padding-top: 53px;
        padding-bottom: 28px;
        .fullForms-title {
            font-weight: 800;
            font-size: 22px;
        }
    }
}
