@use "../../_vars.scss" as vars;


section#hook_lateral_shortcuts {
    position: fixed;
    bottom: 30px;
    right: 25px;
    z-index: 10;

    div.lateral_shortcut {
        padding: 0;
        background-color: vars.$color2;
        width: 64px;
        height: 64px;
        display: flex;
        &.lateral_shortcut_black {
            background-color: vars.$black;
            .text-container {
                background-color: vars.$black;
            }
        }

        button {
            border: 0;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px;
            position: relative;
            i.icon {
                transition: 0.4s ease;
                text-align: center;
                display: flex;
                padding: 8px;
                svg {
                    fill: #fff;
                    height: 30px;
                    width: 30px;
                    transform: unset;
                    margin-right: unset;
                }
            }
            &:hover {
                color: vars.$black;
            }
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px;
            position: relative;
            &:hover {
                div.text-container {
                    display: flex;
                    max-width: 1000px;
                    padding: 0 25px;
                }
            }
        }
        .text-container {
            overflow: hidden;
            position: absolute;
            text-wrap: nowrap;
            white-space: nowrap;
            top: 0;
            left: 2px;
            transform: translateX(-100%);
            background-color: #EF7D00;
            z-index: -1;
            display: flex;
            max-width: 0;
            color: #fff;
            text-transform: uppercase;
            font-size: 20px;
            text-decoration: none;
            font-weight: bold;
            transition: 0.6s ease;

            align-items: center;
            height: 100%;
        }
    }
}

@media (max-width: 1200px) {
    section#hook_lateral_shortcuts div.lateral_shortcut:not(.always_displayed) {
        display: none;
    }

    section#hook_lateral_shortcuts div.always_displayed .text-container {
        display: flex;
        max-width: 1000px;
        padding: 0 25px;
    }

}