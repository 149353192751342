@use "../../_vars.scss" as vars;


.bloc_page_menu {
    padding: 25px;
    margin-top: 30px;
    margin-bottom: 30px;
    .bloc_page_menu_link {
        text-decoration: none;
        padding: 7px 17px;
        background-color: vars.$black;
        color: vars.$white;
        border: 0;
        font-size: 18px;
        font-weight: 500;
        transition: 0.4s ease;
        &:hover {
            background-color: vars.$color2;
            color: #FFF;
        }
    }
}