/* Couleurs loading */
$black: #1D1D1B;
$white: #fff;
$grey: #f3f3f3;
$grey_light: #EFEFEF;
$dark_grey: #1E1E21;
$loadColor: #CE061D;

$font1: 'Inter', sans-serif;
$font2: 'Aldrich', sans-serif;

$color1: #D30B1A; // rouge
$color2: #EF7D00; // orange
$color3: #FFBC00; // jaune
$color4: #17171A; // background header & footer

$color_tarteaucitron: $font1;
$color_tarteaucitron: #333;
$color_tarteaucitron2: #FFF;

$color_intranet_1: #222222;
$color_intranet_2: #b90000;
$color_intranet_3: #ffcaca;
$color_intranet_4: #e6e6e6;


/* A personnaliser selon les besoins */
$rupture_1_min: 991px;
$rupture_1_max: 1600px;

$rupture_1_sec_max: 1400px;

$rupture_2_min: 991px;
$rupture_2_max: 1200px;

$rupture_3_min: 100px;
$rupture_3_max: 1199px;

$rupture_4_min: 100px;
$rupture_4_max: 768px;


$rupture_5_max: 576px;