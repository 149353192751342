@use "Scss/_vars.scss" as vars;

div#share-modal {
    div.modal-body {
        div.socials {
            button {
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 50px;
                width: 50px;
                &#share-facebook {
                    border: 1px solid #4267B2;
                    i > svg {
                        fill:#4267B2;
                    }
                    &:hover {
                        background-color: #4267B2;
                        i > svg {
                            fill: vars.$white
                        }
                    }
                }
                &#share-linkedin {
                    border: 1px solid #0A66C2;
                    i > svg {
                        fill: #0A66C2;
                    }
                    &:hover {
                        background-color: #0A66C2;
                        i > svg {
                            fill: vars.$white
                        }
                    }

                }
            }
        }
        button {
            border: 0px;
            background-color: transparent;
            padding: 0;
            transition: 0.5s ease;
            &.btn-orange {
                background-color: vars.$color2;
                color: vars.$white;
                padding: 0px 10px;
                font-weight: 600;
                display: flex;
                align-items: center;
                gap: 5px;
                &:hover {
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                }
            }
            i > svg {
                transition: 0.5s ease;
                height: 20px;
                width: 20px;
                transform: unset;
                margin: 0;
            }
        }
        input#link-container {

        }
    }
}