@use "../../_vars.scss" as vars;






#foot_center {
    .menu_bas {
        font-size: 20px;
        display: flex;
        list-style: none;
        .nav-item {
            a {
                font-weight: bold;
                text-decoration: none;
                color: vars.$white;
                transition: 0.4s ease;
                &:hover {
                    color: vars.$color2;
                }
            }
        }
    }
}