@use "../../_vars.scss" as vars;

.hook_socials{
  .link{
    .icon{
      svg{
        width: 35px;
        height: 35px;
        fill: vars.$color2;
        transition-duration: 0.4s;
        margin: 0;
      }
    }
    &:hover{
      .icon{
        svg{
          transition-duration: 0.4s;

        }
      }
      .icon_facebook{
        svg{
          fill:#4267B2;
        }
      }
      .icon_linkedin{
        svg{
          fill:#4267B2;
        }
      }
      .icon_twitter{
        svg{
          fill:#1DA1F2;
        }
      }
      .icon_instagram{
        svg{
          fill:#f09433;
        }
      }
      .icon_youtube{
        svg{
          fill:#FF0000;
        }
      }
    }
  }
}