@use "../../_vars.scss" as vars;

@import "hooks/_connexion.scss";

:root{
}

body.intranet{
  .hook_offline{
    position: relative;
    top: 50%;
    transform: translateY(-55%);
    @media (min-width: 1400px) {
      width: 27%;
    }
    @media (min-width: 991px) and (max-width: 1399px) {
      width: 50%;
    }
  }
}
body.logged {
  #global[full]{
    .sidebar-content{
      margin-left: -15%;
      //width: 0;
      z-index: -1;
    }
    #content{
      left: 0;
      width: 100%;
    }
  }
  #breadcrumbs{
    margin: 0;
    padding: 1.5rem;
    .breadcrumb{
      margin: 0;
    }
  }
  section{
    padding: 0;
  }
  .hook_user_connected{
    .dropdown-toggle{
      &:hover{
        cursor: pointer;
      }
    }
  }
  .wrapper {
    align-items: stretch;
    display: flex;
    width: 100%;
    padding: 0;
  }
  #content_center {
    padding: 1rem;
  }
  .navbar-toggler-full{
    display: none;
  }
  @media (min-width: 1400px) {
    #content_center{
      padding: 1.5rem;
      nav{
        margin: 0;
      }
      nav{
        padding: 1.5rem;
      }
    }
    .navbar-toggler{
      display: none;
    }
    .navbar-toggler-full{
      display: block;
    }
    .offcanvas {
      position: inherit;
      top: inherit;
      left: inherit;
      width: inherit;
      transform: inherit;
      visibility: inherit;
      bottom: inherit;
      height: 100%;
    }
    .offcanvas-header .btn-close{
      display: none;
    }
  }
  .offcanvas-header .btn-close{
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .offcanvas-body{
    padding: 0;
  }
  .sidebar-content {
    background-color: vars.$black;
    position: fixed;
    height: 100%;
    transition-duration: 0.4s;
    .offcanvas-start{
      background: vars.$black;
    }
    @media (min-width: 1400px) {
      width: 15%;
    }
    z-index: 2;
    .sidebar-brand {
      padding: 1rem;
      display: table;
      margin: auto;
    }

    .sidebar-nav {
      margin: 0;
      padding: 0;
      list-style-type: none;
      .sidebar-item {
        .sidebar-link {
          text-decoration: none;
          padding: 1rem;
          text-align: left;
          transition-duration: 0.2s;
          color: vars.$white;
          display: block;

          &:hover {
            background-color: vars.$white;
            color: vars.$black;
            transition-duration: 0.2s;

            i {
              svg {
                fill: vars.$black;
              }
            }
          }
        }
      }
    }
  }

  #content {
    position: relative;
    width: 100%;
    background-color: vars.$grey;
    transition-duration: 0.4s;
    @media (min-width: 1400px) {
      left: 15%;
      width: 85%;
    }
    display: block;
  }

  body[data-layout=boxed] .wrapper {
    border-left: 1px solid #dee6ed;
    border-right: 1px solid #dee6ed;
    margin: 0 auto;
    max-width: 1800px;
    overflow: hidden
  }
}