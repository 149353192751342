@use "Scss/_vars.scss" as vars;

section#tpl_properties {
    h2 {
        position: relative;
        text-transform: uppercase;
        font-weight: 900;
        margin-top: 100px;
        margin-bottom: 60px;
        &:before {
            content: "";
            background-image: url("/public/images/title-decoration2.svg");
            width: 67px;
            height: 66px;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-50%, -75%);
        }
    }
    #paginationFilters {
        background-color: vars.$color2;
        padding: 35px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        column-gap: 20px;

        div.ms-parent  {
            flex-grow: 1;
            min-width: 230px;
            max-width: 230px;
            button.ms-choice {
                height: 50px;
                border-radius: 0;
                border: 0;
                > span {
                    color: vars.$color2;
                    border-right: 2px solid #1D1D1B;
                    padding: 0.75rem 0.75rem;
                    right: 45px;
                }
                div.icon-caret {
                    background-image: url("/public/images/icon_awesome-angle-down.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 46px;
                    height: 50px;
                    top: 0;
                    right: 0;
                    margin-top: 0;
                    border-color: transparent;
                    border-style: none;
                    border-width: 0;
                }
            }
        }
        div.ts-wrapper {
            min-width: 230px;
            max-width: 230px;
            border-radius: 0;
            color: vars.$color2;

            background-position: right 1rem center;
            position: relative;
            &:after {
                content: "";
                position: absolute;
                width: 2px;
                height: 100%;
                right: 3.25rem;
                top: 0;
                background-color: #000;
            }
            .ts-control {
                padding-right: 3.25rem !important;
                border-radius: 0;
                .item {
                    overflow: hidden;
                    text-wrap: nowrap;
                    white-space: nowrap;
                    max-width: calc(100% - 5px);
                    padding: 0.375rem 0rem !important;
                    color: vars.$color2 !important;
                }
                input {
                    min-height: 36px !important;
                    min-width: unset;
                    padding: 0.375rem 0rem !important;
                    &::placeholder {
                        color: vars.$color2 !important;
                    }
                }

            }
            div.ts-dropdown div.option {
                font-size: 14px;
            }
        }
        div.ts-wrapper.has-items .ts-control > input {
            margin: 0 !important;
            width: 5px;
            padding: 0 !important;
        }
        .clear-button {
            display: none;
        }
        input.form-control {
            border-radius: 0;
            padding: 0.750rem 0.750rem;
            color: vars.$color2 !important;
            &::placeholder {
                color: vars.$color2 !important;
            }
        }
    }

    #elemsContainer {
        margin-top: 50px;
        row-gap: var(--bs-gutter-x);
    }

    #pagination-container {
        margin-bottom: 82px;
        margin-top: 82px;
        display: flex;
        justify-content: center;

        ul {
            li {
                a {
                    color: vars.$black;
                    font-weight: bold;
                    text-transform: uppercase;
                    border: none;
                    border-radius: 0;
                    font-size: 20px;
                    transition: 0.4s ease;
                    &:hover {
                        color: vars.$white;
                        background-color: vars.$color2;
                    }
                    &:focus {
                        box-shadow: none;
                    }
                }

                &.active {
                    a {
                        color: vars.$white;
                        background-color: vars.$color2;
                    }

                }
            }
        }
    }

    div.hook_properties_property {
        position: relative;
        a.property-link {
            &.altarimmo-selection {
                div.property-info {
                    background-color: rgba(239, 125, 0, 0.75);
                    h3 {
                        color: vars.$white;
                    }
                }
                div.actions-container {
                    font-size: 24px;
                    color: vars.$white;
                    button {
                        i.icon > svg {
                            path {
                                stroke: vars.$white;
                            }
                            &:hover {
                                path {
                                    stroke: vars.$black;
                                }
                            }
                        }
                        &.fav:not(.favored) {
                            i.icon svg {
                                path {
                                    fill: transparent;
                                }

                                &:hover {
                                    stroke: vars.$black;
                                }
                            }
                        }

                        &:not(.btn-share) {
                            i.icon svg {
                                stroke: vars.$white;
                                fill: transparent;
                            }
                        }

                        &.favored {
                            i.icon svg {
                                stroke: none;
                                fill: vars.$white;
                                path {
                                    fill: vars.$white;
                                    stroke: none;
                                }

                            }

                            &.active {
                                i.icon svg {
                                    stroke: vars.$white;
                                    fill: vars.$white;
                                }
                            }
                        }
                    }
                }
            }
            div.property-transaction {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                background-color: vars.$black;
                color: vars.$white;
                text-transform: uppercase;
                padding: 10px 20px;
                font-size: 20px;
                font-weight: 500;

            }
            div.property-info {
                padding: 75px 35px 20px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                background-color: rgba(255, 255, 255, 0.75);
                h3 {
                    color: vars.$color2;
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 15px;
                }
                h4 {
                    color: vars.$black;
                    font-size: 17px;
                    font-weight: 600;
                }
            }

            div.actions-container {
                font-size: 24px;
                color: vars.$color2;
                position: absolute;
                top: 0;
                z-index: 1;
                right: 10px;
                button {
                    background: transparent;
                    margin: 0;
                    border:0;
                    padding: 10px;
                    i.icon > svg {
                        path {
                            stroke: vars.$color2;
                        }
                        transform: none;
                        height: 30px;
                        width: 30px;
                        margin: 0;
                        &:hover {
                            path {
                                stroke: vars.$black;
                            }

                        }
                    }
                    &.fav:not(.favored) {
                        i.icon svg {
                            path {
                                fill: transparent;
                            }
                            &:hover {
                                stroke: vars.$black;
                            }
                        }
                    }

                    &:not(.btn-share) {
                        padding: 10px;
                        i.icon svg {
                            height: 30px;
                            width: 30px;
                            stroke: vars.$color2;
                            stroke-width: 75px;
                            fill: transparent;
                        }
                    }
                    &.favored {
                        i.icon svg {
                            height: 30px;
                            width: 30px;
                            stroke: none;
                            fill: vars.$color2;

                        }
                        &.active {
                            i.icon svg {
                                stroke: vars.$color2;
                                fill: vars.$color2;
                            }
                        }
                    }
                }
            }
        }
        button.property_btn {
            position: absolute;
            bottom: 22px;
            left: 0;
            padding: 0;
            border: 0;
            background-color: vars.$color2;
        }
    }
}

@media (max-width: 1605px) {
    section#tpl_properties #paginationFilters div.ms-parent {
        min-height: unset;
        max-width: unset;
    }
}

@media (max-width: 1640px) and (min-width: vars.$rupture_5_max) {
    section#tpl_properties {
        #paginationFilters {
            column-gap: 20px;
            row-gap: 20px;
            flex-wrap: wrap;
            justify-content: start;
            input.form-control {
                flex-grow: 1;
                flex-basis: 49%;
            }
            div.ts-wrapper {
                flex-grow: 1;
                max-width: unset;
                min-width: unset;
                flex-basis: 32%;
            }
            button {
                margin: auto;
            }
        }
    }
}
@media (max-width: vars.$rupture_5_max) {
    section#tpl_properties {
        #paginationFilters {
            column-gap: 20px;
            row-gap: 20px;
            flex-wrap: wrap;
            justify-content: start;
            input.form-control {
                flex-basis: 100%;
            }
            div.ts-wrapper {
                max-width: unset;
                min-width: unset;
                flex-basis: 100%;
            }
            button {
                margin: auto;
            }
        }
    }
}