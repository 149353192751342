@use "../../_vars.scss" as vars;

.hook_slider{
  .hook_slider_content{
    .hook_slider_content_item{
      .hook_slider_content_item_image{
        background-size: cover;
        background-position: center center;
        min-height: 500px;
      }
      .hook_slider_content_item_desc{
        left: 0;
        width: 100%;
        bottom: 0;
        padding-bottom: 3rem;
        background-color: rgba(0,0,0,0.4);
        .hook_slider_content_item_desc_title{
          font-size: 20px;
          /*font-weight: bold;*/
        }
      }
    }
  }
}