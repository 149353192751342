@use "_vars.scss" as vars;


div.g-recaptcha {
    div.grecaptcha-badge {
        visibility: hidden;
    }
}

#btn_pagination {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.6s ease;
    background-color: vars.$color2;
    color: vars.$white;
    font-weight: 600;
    border-radius: 0;
    &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

}

h1 {
    font-weight: 800;
    text-transform: uppercase;
    color: vars.$black;
    margin-bottom: 40px;
    margin-top: 110px;
    position: relative;
    &:before {
        content: "";
        background-image: url("/public/images/title-decoration2.svg");
        width: 67px;
        height: 66px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, -75%);
    }
}

div#returnTopBtnContainer {
    position: fixed;
    bottom: 0;
    right: 103px;
    button#returnTopBtn {
        border: 0;
        background-color: vars.$color2;
        i.icon {
            transition: 0.4s ease;
            text-align: center;
            display: flex;
            padding: 8px;
            svg {
                fill: #fff;
                height: 16px;
                width: 16px;
                transform: unset;
                margin-right: unset;
            }
        }
        &:hover {
            i.icon {
                svg {
                    fill: vars.$black;
                }
            }
        }
    }
}

html.menu_open {
    overflow: hidden;
}


.password_bloc{
    position: relative;
    .icon{
        position: absolute;
        right: 0;
        top: 0;
        padding: 0.375rem 0.75rem;
        height: 100%;
        min-width: 36px;
        cursor: pointer;
        svg{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            right: 11px;
        }
    }
}

div.lightbox {
    div.modal-fullscreen {
        .btn-close {
            margin-right: 17px;
        }
        div.ratio {
            max-height: 100vh;
        }
    }
}


.orange-custom-btn {
    font-weight: 600;
    color: vars.$white !important;
    text-decoration: none;
    height: fit-content;
    font-size: 15px;
    padding: 10px 70px 10px 80px;
    background: url("/public/images/btn-orange-before.svg") left center no-repeat, url("/public/images/btn-orange-after.svg") right 10px center no-repeat, linear-gradient(to right, transparent, transparent 68px, vars.$color2 0%);
    background-size: contain;
    transition: 0.4s ease;
    &:hover {
        color: vars.$white;
    }
    
}

.white-custom-btn {
    font-weight: 600;
    color: vars.$color2 !important;
    text-decoration: none;
    height: fit-content;
    font-size: 15px;
    padding: 10px 70px 10px 80px;
    background: url("/public/images/btn-white-before.svg") left center no-repeat, url("/public/images/btn-white-after.svg") right 10px center no-repeat, linear-gradient(to right, transparent, transparent 68px, vars.$white 0%);
    background-size: contain;
    &:hover {
        transition: 0.4s ease;
        color: vars.$color2;
    }
}



button.orange-custom-btn, button.white-custom-btn {
    border: 0;
}

h1,h2 {
    font-weight: 800 !important;
    font-size: 40px;
}

h1, h2, h3 {
    &:before {
        background-size: cover;
    }
}

body:not(.body_home) {
    main#content {
        margin-top: 77px;
    }
}



.special-content {
    max-width: calc(100% - 240px);
    width: 100%;
    margin-right: 120px;
    margin-left: 120px;
}


@media (max-width: 1200px) {
    body:not(.body_home) {
        main#content {
            margin-top: 0px;
        }
    }
    .special-content {
        max-width: 100%;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
}

// LG
@media (max-width: 1200px) and (min-width: 992px) {
    h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 34px;
    }
    h3 {
        font-size: 30px;
    }

    h1,h2,h3 {
        width: fit-content;
        &:before {
            background-size: cover;
            width: 50px !important;
            height: 50px !important;
        }
    }
}

// MD
@media (max-width: 991px) and (min-width: 768px) {
    h1 {
        font-size: 34px;
    }
    h2 {
        font-size: 30px;
    }
    h3 {
        font-size: 26px;
    }
    h1,h2,h3 {
        width: fit-content;
        &:before {
            background-size: cover;
            width: 50px !important;
            height: 50px !important;
        }
    }
}

@media (max-width: 767px) and (min-width: 576px) {
    h1 {
        font-size: 30px;
    }
    h2 {
        font-size: 26px;
    }
    h3 {
        font-size: 22px;
    }

    h1,h2,h3 {
        width: fit-content;
        &:before {
            background-size: cover;
            width: 40px !important;
            height: 40px !important;
        }
    }
}

@media (max-width: 575px) {
    h1 {
        font-size: 28px;
    }
    h2 {
        font-size: 22px;
    }
    h3 {
        font-size: 18px;
    }

    h1,h2,h3 {
        width: fit-content;
        &:before {
            background-size: cover;
            width: 40px !important;
            height: 40px !important;
        }
    }
}

@media (max-width: vars.$rupture_5_max) {
    .white-custom-btn {
        background: url("/public/images/btn-white-after.png") right center no-repeat, linear-gradient(vars.$white, vars.$white);
        padding: 10px 80px 10px 20px;
        background-position: right;
        background-size: 35%;

    }
    .orange-custom-btn {
        background: url("/public/images/btn-orange-after.png") right center no-repeat, linear-gradient(vars.$color2, vars.$color2);
        padding: 10px 80px 10px 20px;
        background-position: right;
        background-size: 35%;
    }

    a.orange-custom-btn, a.white-custom-btn {
        background-size: contain;
    }
}