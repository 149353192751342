@use "../../_vars.scss" as vars;


section.hook_shortcuts {
    margin-bottom: 150px;
    margin-top: 137px;
    h2 {
        font-weight: bolder;
        text-transform: uppercase;
        margin-bottom: 47px;
        position: relative;
        &:before {
            content: "";
            background-image: url("/public/images/title-decoration2.svg");
            width: 67px;
            height: 66px;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-45%, -100%);
        }
    }
    div.shortcuts_wrapper {
        position: relative;
        img#shortcuts-top-corner {
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(40%,-40%);
        }
        img#shortcuts-bottom-corner {
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translate(-38%,44%);
        }
        div.row {
            row-gap: 1.5rem;
        }
        div.hook_shortcuts_shortcut {
            position: relative;
            a {
                text-decoration: none;
                display: block;
                width: 100%;
                img {
                    width: 100%;
                    object-fit: cover;
                }
                &:hover {
                    color: vars.$white;
                }
            }


            img {

            }
            button.shortcut_btn {
                position: absolute;
                bottom: 22px;
                left: 0;
                padding: 0;
                border: 0;
                background-color: vars.$color2;
            }
            div.shortcut_header {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 182px;
                background-color: rgba(239, 125, 0, 0.7);
                h3 {
                    color: white;
                    font-weight: 600;
                    padding: 35px 30px;
                }
            }
        }
    }
}

section#tpl_clients {
    margin-bottom: 140px;
}

@media (max-width: 1500px) and (min-width: 1200px) {
    section.hook_shortcuts {
        div.shortcuts_wrapper {
            div.hook_shortcuts_shortcut {
                div.shortcut_header {
                    h3 {
                        font-size: 1.25rem;
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    section.hook_shortcuts {
        div.shortcuts_wrapper {
            div.hook_shortcuts_shortcut {
                max-width: 500px;
                margin: auto;
            }
        }
    }
}

@media (max-width: vars.$rupture_5_max) {
    section.hook_shortcuts {
        margin-bottom: 50px;
        margin-top: 45px;
        h2 {
            font-weight: 800;
        }
        div.shortcuts_wrapper {
            img#shortcuts-top-corner {
                display: none;
            }
            img#shortcuts-bottom-corner {
                display: none;
            }
            div.hook_shortcuts_shortcut {
                max-width: 500px;
                div.shortcut_header {
                    height: fit-content;
                    h3 {
                        font-size: 22px;
                        padding:10px 13px;
                    }
                }
            }
        }
    }
}

