@use "../../_vars.scss" as vars;


.hook_realisations,#tpl_realisations,#tpl_detail_realisation{
  .realisation{
    a{
      text-decoration: none;
      color: vars.$black;
      transition-duration: 0.4s;
      .name{
        font-size: 1rem;
      }
      &:hover{
        transform: scale(1.02);
        transition-duration: 0.4s;
      }
    }
  }
}