@use "../../_vars.scss" as vars;
#menu_left {
    #left_menu_container {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-evenly;
        li {
            background: url("/public/images/list_style_menu_left.svg") left center no-repeat;
            padding-left: 50px;
            list-style: none;
            font-size: 20px;
            font-weight: 700;
            &::marker {
                text-align: center !important;
            }
            a {
                margin: 5px 15px;
                display: inline-block;
                color: vars.$white;
                text-decoration: none;
            }
        }
    }

}

@media (max-height: 735px) {
    #menu_left {
        #left_menu_container {
            .nav-item {
                font-size: 18px;
            }
        }
    }
}