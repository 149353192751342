@use "_vars.scss" as vars;


@media (max-width: 1200px) {
    .pag_layout_home {
        .row {
            .col-sm-12 {
                >.container-fluid{
                    padding-left: 2rem;
                    padding-right: 2rem;
                }
            }
        }
    }
    .pag_layout_default {
        .container-fluid.special-content {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}

// OVERRIDES BOOTSTRAP 5

.form-control:focus {
    box-shadow: 0 0 0 0.1rem rgba(63,128,234,.25);
}

.container-xxl {
    max-width: 1640px;
}

@media (max-height: 1640px) {
    .container-xxl {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.form-floating {
    label {
        width: unset;
        height: unset;
        padding: 1rem 0 1rem 5px;
        margin-left: 5px !important;
    }
    >.form-control {
        &:not(:placeholder-shown) {
            ~label {
                z-index: 1;
                opacity: unset;
                padding: 0 0px;
                transform: scale(.85) translateY(-0.8rem) translateX(0.5rem);
                background-color: white;
            }
        }
    }
    >.form-control {
        &:focus {
            ~label {
                z-index: 1;
                opacity: unset;
                padding: 0 0px;
                transform: scale(.85) translateY(-0.8rem) translateX(0.5rem);
                background-color: white;
            }
        }
    }
    >select.form-select.tomselected {
        padding:0;
        &~div {
            padding:0;
            div.item {
                padding-top: 0.625rem;
            }
            ~label {
                z-index: 1;
                opacity: unset;
                padding: 0 0px;
                transform: scale(0.85) translateY(-0.8rem) translateX(0.5rem);
                background-color: white;
            }
        }
    }
}
.toastify{
    background: none;
}

table.dataTable {
    thead {
        th {
            input[type="number"] {
                width: unset;
            }
        }
    }
}

.card {
    box-shadow: 0 0 .875rem 0 rgba(41,48,66,.05);
    margin-bottom: 24px;
    --bs-card-spacer-y: 1.25rem;
    --bs-card-spacer-x: 1.25rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-title-color: #495057;
    --bs-card-subtitle-color: ;
    --bs-card-border-width: 0;
    --bs-card-border-color: transparent;
    --bs-card-border-radius: 0.25rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: 0.25rem;
    --bs-card-cap-padding-y: 1rem;
    --bs-card-cap-padding-x: 1.25rem;
    --bs-card-cap-bg: #fff;
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 12px;
    word-wrap: break-word;
    background-clip: border-box;
    background-color: var(--bs-card-bg);
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
    color: var(--bs-body-color);
    display: flex;
    flex-direction: column;
    height: var(--bs-card-height);
    min-width: 0;
    position: relative;
}
