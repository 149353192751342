@use "_vars.scss" as vars;


.intranet{
  footer{
    position: absolute;
  }
}

footer{
  background-color: vars.$black;
  z-index: 0;
  position: relative;
  bottom: 0;
  width: 100%;
  img.foot_background {
    position: absolute;
    top: 75px;
    right: -80px;
  }
  #foot_bottom {
    padding: 1rem 0;
    .link{
      text-decoration: none;
      transition-duration: 0.2s;
      &:hover{
        text-decoration: underline;
        transition-duration: 0.2s;
      }
    }
  }
}
footer#foot{
  #footTop{
    padding: 44px 0 34px;
  }
  #foot_bottom{
    font-size: 14px;
    text-transform: uppercase;
    > div {
      display: flex;
      justify-content: space-between;
    }
    background-color: vars.$black;
    text-align: center;
    color: vars.$color2;
    a{
      color: vars.$color2;
    }
  }
  #foot_right {
    font-weight: 600;
    font-size: 16px;
    text-align: start;
    color: vars.$white;
    span.vcard_name {
      display: none;
    }

     section.hook_socials {
       padding: 0;
     }
  }

  #foot_right, #foot_center {
    margin-top: 85px;
  }
}

@media (max-width: vars.$rupture_2_max) {
  footer{
    img.foot_background {
      bottom: 0;
      right: -210px;
      height: 83%;
      width: unset;
      object-fit: contain;
    }
  }
  footer#foot{
    #foot_left {
      display: none;
    }
    #foot_bottom{
      > div {
        align-items: center !important;
        flex-direction: column;
        justify-content: center;
        row-gap: 11px;
      }
      text-align: center;
      color: vars.$color2;
      a{
        color: vars.$color2;
      }
    }
    #foot_right {
      margin-top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      div.addr_bloc.addr_bloc_left.vcard {
        text-align: center;
        margin: 41px auto 0;
        span.vcard_name {
          text-transform: uppercase;
          font-size: 22px;
          font-weight: bolder;
          display: block;
          margin-bottom: 11px;
        }
        span.tel {
          margin-top: 29px;
        }
      }

      section.hook_socials {
        margin-top: 24px;
      }
    }

    #foot_center {
      margin: 0;
      div.menu_bas {
        display: none;
      }
    }
  }
}